.video-box-container{
	max-width: 600px;
    /* margin: 8% auto; */
    margin: -50px auto 0;
    position: relative;
}


img.videothumbnail {
    width: 100%;
    height: auto;
    opacity:.5;
    cursor: pointer;
    z-index: 50;
   
}
.visible {
    visibility: visible;
    opacity: 1;
    transition: opacity 2s linear;
  }
 .fadeOut {
    /* visibility: hidden; */
    opacity: 0;
    /* transition: opacity 2s linear; */
    transition-property: opacity;
    transition-duration: 2s;
    pointer-events: none;
}
.video-box-container img {
    margin-top: 50px;
    position: absolute;
    cursor: pointer;
    z-index: 80;
    opacity: .5;
}
.video-box-container img.fadeOut {
    opacity: 0;
    transition-property: opacity;
    transition-duration: 3s;
    z-index: 0;
}
.video-container {
    width: -moz-available;
    width: -webkit-fill-available;
    width: stretch;
}
.video-container video{
    width: inherit;
    width: 100%;
    height: auto;
}
.holdTimeDown {
    position: relative;
    height: fit-content;
}
.video-box-container img:hover, .video-box-container .holder:hover {
  opacity:1;
  -webkit-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -o-transition: all.5s ease-in-out;
  -ms-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
}
.video-box-container img.fadeOut:hover{
    opacity:0;
}
.video-box-container .holder:hover {
    z-index: 81;
}

.broadcast {
    display: block;
    width: 100%;
    height: auto;
}


.video-credit-container {
    color: white;
    z-index: 101;
    position: relative;
    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
}
.timecode-credits {
    font-family: "Helvetica Peel";
    font-size: 9pt;
    letter-spacing: inherit;
    margin-bottom: 50px;

}

span.track-seperator {
    margin-right: 5px;
}

.time-column {
    color: #fff;
    font-family: "Helvetica Peel";
    text-transform: uppercase;
    margin-left: 15px;
    margin-right: 5px;
    letter-spacing: 1.25px
}
.first-track span.time-column {
    margin-left: 0;
}
span.credit-title.uppercase {
    font-family: "Helvetica Peel",Arial,Helvetica,sans-serif;
    text-transform: uppercase;
    margin: 20px 0;
    display: table;
    font-size: 11pt;
    letter-spacing: inherit;
}
span.time-column {
    margin-right: 5px;
}
.listing-item {
    display: contents;
}
span.track:hover {
    cursor: pointer;
    opacity: .5;
}

span.bold.band {
    font-family: "Helvetica Peel";
    font-size: 11pt;
}

span.track {
    margin-right: 5px;
    margin-left: 5px;
}

span.track.first-track {
    margin-left: 0px;
}


@media (max-width: 524px) {
    .video-credit-container {
        z-index: 101;
        position: relative;
        justify-content: center;
        align-items: center;
    }
    span.track-seperator {
        display: none;
    }

    span.track {
        width: 100%;
        display: inline-block;
        margin-left: 0;
        margin-bottom: 10px;
    }

    
    span.time-column {
        margin-right: 10px;
        width: 27%;
        /* width: calc(30% - 10px); */
        display: inline-table;
    }

    span.artist-column{
        width: 70%;
        display: inline-table;
        float: right;
        line-height: normal;
        letter-spacing: initial;
    }
    span.mobile-break {
	    width: 100%;
	    display: inline-block;
	    text-align: left;
	}
}


@media (max-width: 410px){
	 span.time-column {
        margin-right: 10px;
        width: 27%;
        /* width: calc(35% - 10px); */
        display: inline-table;
    }
     span.artist-column{
        width: 65%;
        display: inline-table;
        float: right;
        line-height: normal;
        letter-spacing: initial;
    }
}


.videoComps.fullscreen {
    height: 100vh;
    width: auto;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
}

.fullscreen .holder {
   display: none;
}

.fullscreen .video-container {
    width: -webkit-fill-available;
    height: 100vh;
}

.swipableTimeCompent {
    height: 100vh;
    width: 100vw;
    background-color: #000000d4;
    opacity:0;
    top: 90vh;
    left: 0;
    position: absolute;
    z-index: 101;
    align-items: center;
    display: flex;
    transition: top 2s cubic-bezier(0.68, -0.02, 0.25, 1) 0s; 
}

.swipableTimeCompent.active{
    top: 0;
    opacity:1;
}



.swipableTimeCompent .timecode-credits {
    width: 86%;

}