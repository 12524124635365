@font-face {
  font-family: 'Gosha Sans Regular';
  src: local('Gosha Sans Regular'), local('GoshaSans-Regular'),
  url('//static.peel.global/fonts/002/GoshaSans-Regular.eot'),
  url('//static.peel.global/fonts/002/GoshaSans-Regular.eot?#iefix') format('embedded-opentype'),
  url('//static.peel.global/fonts/002/GoshaSans-Regular.woff') format('woff'),
  url('//static.peel.global/fonts/002/GoshaSans-Regular.woff2') format('woff2'),
  url('//static.peel.global/fonts/002/GoshaSans-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Gosha Sans Ultralight';
  src: local('Gosha Sans Ultralight'), local('GoshaSans-Ultralight'),
  url('//static.peel.global/fonts/002/GoshaSans-Ultralight.eot'),
  url('//static.peel.global/fonts/002/GoshaSans-Ultralight.eot?#iefix') format('embedded-opentype'),
  url('//static.peel.global/fonts/002/GoshaSans-Ultralight.woff') format('woff'),
  url('//static.peel.global/fonts/002/GoshaSans-Ultralight.woff2') format('woff2'),
  url('//static.peel.global/fonts/002/GoshaSans-Ultralight.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Helvetica Peel';
  src: local('Helvetica Peel'), local('HelveticaPeel-Bold'),
  url('//static.peel.global/fonts/002/HelveticaPeel-Bold.eot'),
  url('//static.peel.global/fonts/002/HelveticaPeel-Bold.eot?#iefix') format('embedded-opentype'),
  url('//static.peel.global/fonts/002/HelveticaPeel-Bold.woff') format('woff'),
  url('//static.peel.global/fonts/002/HelveticaPeel-Bold.woff2') format('woff2'),
  url('//static.peel.global/fonts/002/HelveticaPeel-Bold.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
font-family: "peel";
src:local('peel'),
  url("//static.peel.global/fonts/002/peel.eot"),
  url("//static.peel.global/fonts/002/peel.eot?#iefix") format("embedded-opentype"),
  url("//static.peel.global/fonts/002/peel.woff") format("woff"),
  url("//static.peel.global/fonts/002/peel.woff2") format("woff2"),
  url("//static.peel.global/fonts/002/peel.ttf") format("truetype"),
  url("//static.peel.global/fonts/002/peel.svg#peel") format("svg");
font-weight: normal;
font-style: normal;
}

[data-icon]:before {
font-family: "peel" !important;
content: attr(data-icon);
font-style: normal !important;
font-weight: normal !important;
font-variant: normal !important;
text-transform: none !important;
line-height: 1;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
font-family: "peel" !important;
font-style: normal !important;
font-weight: normal !important;
font-variant: normal !important;
text-transform: none !important;
line-height: 1;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

.icon-peel:before {
content: "\61";
}
.icon-play:before {
content: "\62";
}
.icon-pause:before {
content: "\63";
}
.icon-play-1:before {
content: "\64";
}
.icon-circle-o:before {
content: "\65";
}
.icon-volume:before {
content: "\66";
}
.icon-mute:before {
content: "\67";
}
.icon-fullscreen:before {
content: "\68";
}
.icon-peel-arrow-glyph:before {
content: "\69";
}
body {
  font-family: "Gosha Sans Regular",Arial,Helvetica,sans-serif;
  margin: 0;
  padding: 0;
  border: 0;
  color: #000;
  line-height: 1.4;
  width: 100%;
  -webkit-text-size-adjust: 100%;
  background: #000000;
  vertical-align: baseline;
  background-color: black;
  position: absolute;
  top:0;
}
.max-width {
  max-width: 1200px;
  margin: 49px auto;
}
.mobile-gone{
  display: none;
}
.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}



div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }
 
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }
 
html {
margin: 0;
padding: 0;
border: 0;
font: inherit;
vertical-align: baseline;
overflow-x: hidden;
color: white;
} 

a, a:-webkit-any-link, .home-link{
	text-decoration: none;
	color: white;
  -webkit-transition: all .5s, ease-in-out;
  -moz-transition: all .5s, ease-in-out;
  -o-transition: all .5s, ease-in-out;
  -ms-transition: all .5s, ease-in-out;
  transition: all .5s, ease-in-out;

}
a:hover, .home-link:hover{
  color: darken(white, 30%);
  -webkit-transition: all .5s, ease-in-out;
  -moz-transition: all .5s, ease-in-out;
  -o-transition: all .5s, ease-in-out;
  -ms-transition: all .5s, ease-in-out;
  transition: all .5s, ease-in-out;
}

html,
body {
  position: relative;
  height: 100%;
  width: 100%;
  /* overflow-x: hidden;
  overflow-y: scroll; */

}
body {
  font-family: 'Gosha Sans Regular, Arial,Helvetica,sans-serif';
  margin: 0;
  padding: 0;
  border: 0;
  color: #000;
  line-height: 1.4;
  width: 100%;
  -webkit-text-size-adjust: 100%;
  background: #000000;
  vertical-align: baseline;
  background-color: black;
  position: absolute;
  top:0;
}
a.left-nav-span-link {
    font-size: 13px;
    transition: none;
}
body.admin-bar, body.admin-bar .view, body.admin-bar .page{
	overflow: scroll!important;
}
 
@media all and (width: 1024px) and (height: 691px) and (orientation: landscape) {
  html,
  body {
    height: 671px;
  }
}

@media all and (width: 1024px) and (height: 692px) and (orientation: landscape) {
  html,
  body {
    height: 672px;
  }
}

ol, ul {
  list-style: none; }
 
blockquote, q {
  quotes: none; }
 

blockquote:before, blockquote:after {
    content: '';
    content: none; 
} 

q:before, q:after {
    content: '';
    content: none; 
}
 
table {
  border-collapse: collapse;
  border-spacing: 0; }



a,
input,
textarea,
select {
  outline: 0;
}


p {
  margin: 1em 0;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
}


@media (max-width: 768px) {
  .tablet-gone{
		display: none!important;
	}
}

@media (max-width: 516px) {
  .mobile-gone{
		display: none!important;
	}
}