video.broadcast.Hidden {
    pointer-events: none;
     opacity: 0;
     position: fixed;
     bottom: 0;
     right: 0;
     z-index: 1;
 }
 * {
    margin: 0;
    padding: 0;
    box-sizing: border-box; }

  
  /* body {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; } */

  /* canvas {
    background: #000;

    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2; 
    display: block;
  } */

    .VideoSlotMachine {
      height: auto;
  }
  iframe.iFrameFull {
    position:absolute;
    height:100vh;
    width:100vw;
    top:0px;
    left:0;
}
  #two {
    mask-image: linear-gradient(to right, transparent 50%, black 50%);
    -webkit-mask-image: linear-gradient(to right, transparent 50%, black 50%); }
  
  .unmute {
    display: flex;
    align-items: flex-end;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 26vh;
    padding: 18px;
    z-index: 98;
    cursor: pointer; }
  
  .clicktarget {
    position: absolute;
    width: 100%;
    display: block;
    height: 95px;
    z-index: 9999; }
  
  .blink {
    animation: blink 500ms steps(1, end) infinite; }
  
  .unmute svg {
    opacity: 0;
    animation: fademute 5s;
    -webkit-animation: fademute 5s;
    animation-delay: 1s;
    -webkit-animation-delay: 1s;
    /* Safari and Chrome */
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    animation-fill-mode: forwards; }
  
  @keyframes fademute {
    from {
      opacity: 0; }
    to {
      opacity: 1; } }
  
  @-webkit-keyframes fademute {
    from {
      opacity: 0; }
    to {
      opacity: 1; } }
  
  @keyframes blink {
    0% {
      opacity: 0; }
    50% {
      opacity: 1; } }
  