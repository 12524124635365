.newsPageContainer {
    display: flex;
    position: relative
}

.newsNavContainer {
    width: 50%;
    /* margin-top: 60px; */
    display: inline-block;
    z-index: 100
}

.dropdown-content.accordion-content.newsSubContent.pressDropDownConent {
    width: 100%
}

.time-column.newsBarPublication {
    vertical-align: super;
    font-size: x-small;
    letter-spacing: .23em;
    /* margin-left: 2px; */
    margin-left: 10px;
    align-self: baseline;
    margin-top: 4px;
}

.dateRow {
    color: #fff;
    font-family: "Helvetica Peel";
    text-transform: uppercase;
    margin-left: 15px;
    font-size: .75em;
    letter-spacing: 1.25px
}

.headlineRow {
    display: flex
}
span.checkoutIcon.icon-peel-arrow-glyph:before {
    padding-right: 11px;
    /* display: flex; */
    align-content: center;
}
.dateRow {
    color: #fff;
    margin-left: 0;
    margin-top: 10px
}

.articleExcerpt {
    color: #fff;
    font-family: "Helvetica Peel";
    text-transform: uppercase;
    margin-top: 40px;
    letter-spacing: 1.25px
}

.readMore {
    display: block;
    margin-top: 20px;
    font-family: "Helvetica Peel",Arial,Helvetica,sans-serif;
}


.readMore a.external.underline {
    height: auto;
    letter-spacing: 1.25px
}

.articleContainer {
    margin-top: 20px;
    scroll-margin: 40px;
}
.articleContainer.articleReleasesContainer {
    scroll-margin: -1px;
}

.newsArticleContainer {
    width: 49%;
    display: inline-block;
    /* margin-top: 60px; */
    position: relative
}
.email-octopus-form-row {
    width: 0;
}
.hovernav {
    opacity: 0;
    z-index: 99;
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    -ms-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
    animation: fadenavigation 5s;
    -webkit-animation: fadenavigation 5s;
    animation-delay: 0s;
    -webkit-animation-delay: 0s;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1
}
.navi-max-width:hover .hovernav{
     opacity: 1;
}
@keyframes fadenavigation {
    from {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@-webkit-keyframes fadenavigation {
    from {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

.page-wrapper:hover .hovernav {
    opacity: 1
}
.dropdown-content.accordion-content.newsSubContent.pressDropDownConent {
    display: none
}

.dropdown-content.accordion-content.newsSubContent.pressDropDownConent.active,.hoveringChildren .dropdown-content.accordion-content.newsSubContent.pressDropDownConent {
    display: block
}

.articleSpacer {
    width: 1%
}

.marquee.newsMarquee {
    color: #fff;
    position: absolute;
    bottom: 0;
    margin-bottom: 10px;
    letter-spacing: 1.25px;
    width: 100%
}

.newsFooter {
    color: #fff;
    position: fixed;
    top: 96vh;
    width: 100%;
    overflow: hidden;
    height: 40px;
    margin-bottom: 10px
}

.dropdown.accordion:focus,.dropdown.accordion:active,.accordion-title:focus,.accordion-title:active {
    border: 0!important;
    outline-color: transparent;
    outline-width: inherit
}

.articleContainer .articleContainer, .releaseArticleContainer .articleContainer {
    display: none
}

.articleContainer .articleContainer.active,  .releaseArticleContainer .articleContainer.active{
    display: block!important
}

.isHovering .articleContainer.active {
    display: none!important
}

.isHovering .articleContainer.active.isHovering,.newsContainerArticles .articleContainer.active {
    display: block!important
}

.release-gif {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0
}

.newsFooter span {
    display: inline-block;
    white-space: nowrap;
    color: #00112c;
    width: var(--tw);
    text-shadow: var(--tw) 0 currentColor,calc(var(--tw) * 2) 0 currentColor,calc(var(--tw) * 3) 0 currentColor,calc(var(--tw) * 4) 0 currentColor,calc(var(--tw) * 5) 0 currentColor,calc(var(--tw) * 6) 0 currentColor;
    will-change: transform;
    animation: marquee var(--ad) linear infinite;
    animation-play-state: running;
    color: #fff;
    font-size: 1.5em
}

@keyframes marquee {
    0% {
        transform: translateX(0)
    }

    100% {
        transform: translateX(-100%)
    }
}

.subscribeContainer {
    border-top: 1pt solid #fff;
    border-bottom: 1pt solid #fff;
    margin-top: 20px;
    position: relative
}
.accordion-content-row {
    /* display: flex!important; */
    flex-direction: row;
    justify-content: flex-start;
}

.accordion-content-row a {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
}
.email-octopus-form-row-subscribe.accordion-content-row {
    margin-top: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 310px
}

.email-octopus-form-row-subscribe.accordion-content-row {
    text-align: left;
    transition: text-align 1s
}

.subscribeContainer.active .email-octopus-form-row-subscribe.accordion-content-row {
    text-align: right;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

form.email-octopus-form {
    display: flex
}

.subscribeContainer.active form.email-octopus-form {
    justify-content: space-between;
    background-color: #0000006e
}

.subButton.dropbtn.accordion-title {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 10px;
    font-size: 24px
}

.email-octopus-form-row-hp {
    position: absolute;
    left: -5000px
}

.subscribeGlyph {
    padding-right: 19px
}

.subscribeContainer {
    width: 100%;
    transition: width 1s;
    max-width: 1200px
}

.subscribeContainer.active {
    width: 90vw;
    max-width: 1200px
}

.subscribeContainer .subButton.dropbtn.accordion-title {
    padding-left: 0;
    font-weight: 100;
    display: inline-block;
    cursor: pointer
}

.subscribeContainer.active .subscribeText.dropbtn.accordion-title {
    padding-left: 10px
}

.subscribeContainer .icon-peel-arrow-glyph.subscribeGlyph {
    display: none
}

.subscribeContainer input#field_0 {
    width: 0;
    opacity:0;

}

.subscribeContainer.active input#field_0 {
    height: 33px;
    width: 40vw;
    /* vertical-align: text-top; */
    text-align: left;
    margin-top: 9px;
    margin-bottom: 10px;
    font-size: 1.5em;
    display: block;
    transition: width 1s;
    text-transform: uppercase;
    max-width: 870px;
    background-color: transparent!important;
    border: 0;
    opacity:1;
    color:white;
    margin-top: 11px;
}

.subscribeContainer.active .icon-peel-arrow-glyph.subscribeGlyph {
    display: inline
}

.email-octopus-form-wrapper.subscribeContainer input#field_0::placeholder {
    color: transparent;
    opacity: 0;
    transition: opacity 1s
}
.fadeFullWidth{
    width: 100%;
    display: contents;
}
.email-octopus-form-wrapper.subscribeContainer.active input#field_0::placeholder {
    color: #ffffff85!important;
    opacity: 1!important;
    transition: opacity 1s!important;
    height: 33px!important;
    line-height: 33px!important;
    font-family: "Helvetica Peel"!important;
    letter-spacing: 1px!important;
    vertical-align: sub!important;
    font-size: medium!important
}

input#field_0::placeholder {
    text-align: left;
    font-size: .65em!important
}

span.hoverShow {
    display: none;
    font-size: x-small
}

span.hoverHide {
    font-size: x-small
}

.newsContentRow:hover {
    cursor: pointer
}

.newsContentRow:hover span.hoverShow {
    display: contents
}

.newsContentRow:hover span.hoverHide {
    display: none
}

.subscribeContainer.active.submit {
    transition: width 5s!important;
    width: 100%
}

.subscribeContainer.active.submit input#field_0 {
    width: 0;
    transition: 3s
}

p.email-octopus-error-message,p.email-octopus-success-message {
    position: absolute;
    width: 100%;
    color: #fff;
    font-family: "Helvetica Peel";
    text-transform: uppercase;
    font-style: italic;
    letter-spacing: 1px;
    pointer-events: none
}

.newsburger {
    font: inherit;
    display: inline-block;
    overflow: visible;
    margin: 0;
    padding: 15px;
    cursor: pointer;
    transition-timing-function: linear;
    transition-duration: .15s;
    transition-property: opacity,filter;
    text-transform: none;
    color: inherit;
    border: 0;
    background-color: transparent
}

.newsburger.is-active:hover,.newsburger:hover {
    opacity: .7
}

.newsburger.is-active .newsburger-inner,.newsburger.is-active .newsburger-inner:after,.newsburger.is-active .newsburger-inner:before {
    background-color: #fff
}

.newsburger-box {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px
}

.newsburger-inner {
    top: 50%;
    display: block;
    margin-top: -2px
}

.newsburger-inner,.newsburger-inner:after,.newsburger-inner:before {
    position: absolute;
    width: 40px;
    height: 4px;
    transition-timing-function: ease;
    transition-duration: .15s;
    transition-property: transform;
    border-radius: 4px;
    background-color: #fff
}

.newsburger-inner:after,.newsburger-inner:before {
    display: block;
    content: ""
}

.newsburger-inner:before {
    top: -10px
}

.newsburger-inner:after {
    bottom: -10px
}

.newsburger--spring .newsburger-inner {
    top: 2px;
    transition: background-color 0s linear .13s
}

.newsburger--spring .newsburger-inner:before {
    top: 10px;
    transition: top .1s cubic-bezier(.33333,.66667,.66667,1) .2s,transform .13s cubic-bezier(.55,.055,.675,.19)
}

.newsburger--spring .newsburger-inner:after {
    top: 20px;
    transition: top .2s cubic-bezier(.33333,.66667,.66667,1) .2s,transform .13s cubic-bezier(.55,.055,.675,.19)
}

.newsburger--spring.is-active .newsburger-inner {
    transition-delay: .22s;
    background-color: transparent!important
}

.newsburger--spring.is-active .newsburger-inner:before {
    top: 0;
    transition: top .1s cubic-bezier(.33333,0,.66667,.33333) .15s,transform .13s cubic-bezier(.215,.61,.355,1) .22s;
    transform: translate3d(0,10px,0) rotate(45deg)
}

.newsburger--spring.is-active .newsburger-inner:after {
    top: 0;
    transition: top .2s cubic-bezier(.33333,0,.66667,.33333),transform .13s cubic-bezier(.215,.61,.355,1) .22s;
    transform: translate3d(0,10px,0) rotate(-45deg)
}

.newsburger--spring-r .newsburger-inner {
    top: auto;
    bottom: 0;
    transition-delay: 0s;
    transition-timing-function: cubic-bezier(.55,.055,.675,.19);
    transition-duration: .13s
}

.newsburger--spring-r .newsburger-inner:after {
    top: -20px;
    transition: top .2s cubic-bezier(.33333,.66667,.66667,1) .2s,opacity 0s linear
}

.newsburger--spring-r .newsburger-inner:before {
    transition: top .1s cubic-bezier(.33333,.66667,.66667,1) .2s,transform .13s cubic-bezier(.55,.055,.675,.19)
}

.newsburger--spring-r.is-active .newsburger-inner {
    transition-delay: .22s;
    transition-timing-function: cubic-bezier(.215,.61,.355,1);
    transform: translate3d(0,-10px,0) rotate(-45deg)
}

.newsburger--spring-r.is-active .newsburger-inner:after {
    top: 0;
    transition: top .2s cubic-bezier(.33333,0,.66667,.33333),opacity 0s linear .22s;
    opacity: 0
}

.newsburger--spring-r.is-active .newsburger-inner:before {
    top: 0;
    transition: top .1s cubic-bezier(.33333,0,.66667,.33333) .15s,transform .13s cubic-bezier(.215,.61,.355,1) .22s;
    transform: rotate(90deg)
}

.releaseArticleContainer {
    position: absolute;
    top: 0;
    width: 100%
}

.articleContainer img {
    display: inline-block;
    width: 100%;
    height: auto
}

.newsContainerArticles {
    display: none
}

.newsContainerArticles {
    height: 100vh;
    scroll-snap-align: start;
    display: block;
    position: relative;
    margin-top: 60px
}

@media(max-width: 1440px) {
    .newsFooter {
        top:96vh;
        --tw: 20vw!important;
        --ad: 5s!important;
        z-index: 2
    }

    .newsFooter span {
        text-shadow: var(--tw) 0 currentColor,calc(var(--tw) * 2) 0 currentColor,calc(var(--tw) * 3) 0 currentColor,calc(var(--tw) * 4) 0 currentColor,calc(var(--tw) * 5) 0 currentColor
    }
}

@media(max-width: 1103px) {
    .newsFooter {
        top:95vh;
        --tw: 30vw!important;
        --ad: 5s!important;
        z-index: 2
    }

    .newsFooter span {
        text-shadow: var(--tw) 0 currentColor,calc(var(--tw) * 2) 0 currentColor,calc(var(--tw) * 3) 0 currentColor,calc(var(--tw) * 4) 0 currentColor
    }
}

@media(max-width: 1024px) {
    .newsFooter {
        top:97vh;
        --tw: 30vw!important;
        --ad: 5s!important;
        z-index: 2
    }

    .newsFooter span {
        text-shadow: var(--tw) 0 currentColor,calc(var(--tw) * 2) 0 currentColor,calc(var(--tw) * 3) 0 currentColor,calc(var(--tw) * 4) 0 currentColor
    }
}

@media(max-width: 800px) {
    .newsNavContainer {
        width:90vw;
        transition: 1s max-height;
        margin-top: 0;
        padding-right: 10vw
    }

    .newsNavContainer.is-active {
        width: 90vw;
        overflow: auto;
        transition: 1s max-height;
        max-height: 440px;
        display: inline-table
    }

    .newsPageContainer {
        width: 90vw;
        position: relative
    }

    form.email-octopus-form {
        width: 91vw
    }

    .newsFooter {
        --tw: 33vw!important;
        z-index: 2
    }

    .newsFooter span {
        text-shadow: var(--tw) 0 currentColor,calc(var(--tw) * 2) 0 currentColor,calc(var(--tw) * 3) 0 currentColor
    }

    .subscribeContainer.active input#field_0 {
        width: 49vw;
        background-color: transparent!important;
        min-width: 227px
    }

    .email-octopus-form-row-subscribe.accordion-content-row {
        width: 192px
    }

    .email-octopus-form-wrapper.subscribeContainer.active input#field_0::placeholder {
        background-color: transparent!important;
        display: contents!important
    }

    .newsArticleContainer {
        width: 49%;
        display: inline-block;
        z-index: 1;
        margin-top: 0;
        object-fit: cover;
        pointer-events: none
    }

    .releaseArticleContainer {
        top: 0;
        width: auto;
        object-fit: cover;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 15vh;
        left: 0;
        opacity: .5
    }

    .articleSpacer {
        display: none
    }

    .newsArticleContainer.notactive {
        display: none
    }

    .isMobile .articleContainer {
        display: none
    }

    .articleContainer .headlineRow .dropbtn.accordion-title {
        max-width: 80%
    }

    .articleContainer sub.time-column.newsBarPublication {
        margin-left: 15px
    }
}

@media(max-width: 768px) {
    .releaseArticleContainer {
        top:0;
        width: auto;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        opacity: .5;
        align-items: center;
        justify-content: center;
        display: flex
    }

    .articleContainer.articleReleasesContainer.active {
        display: flex!important;
        height: 100vh;
        align-items: center;
        justify-content: center;
        font-size: 16pt;
        position: relative;
        z-index: 99;
        text-align: center;
        margin-top: -60px
    }

    .articleSpacer {
        display: none
    }

    .subscribeContainer.active input#field_0 {
        width: 37vw;
        background-color: transparent!important;
        min-width: 126px
    }
    .newsContainerArticles .articleContainer{
        display: none;
    }
    .releaseArticleContainer .articleContainer.active {
        margin-top: 0
    }

    .articleContainer.active a.external {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        font-family: "Helvetica Peel",Arial,Helvetica,sans-serif;

    }

    .articleContainer img {
        display: inline-block;
        width: 94%;
        height: auto
    }

    .contactsNavContainer {
        margin-top: 0
    }

    .newsFooter {
        --tw: 33.3333333333vw!important;
        z-index: 2
    }

    .newsFooter span {
        text-shadow: var(--tw) 0 currentColor,calc(var(--tw) * 2) 0 currentColor,calc(var(--tw) * 3) 0 currentColor,calc(var(--tw) * 4) 0 currentColor
    }
}

@media(max-width: 667px) {
    .newsFooter {
        top:96vh;
        --tw: 48vw!important
    }

    .newsFooter span {
        text-shadow: var(--tw) 0 currentColor,calc(var(--tw) * 2) 0 currentColor,calc(var(--tw) * 3) 0 currentColor
    }
}

@media (max-width: 600px){
.newsNavContainer {
    width: 100vw;
  
}
}
@media(max-width: 414px) {
    .newsFooter {
        top:95.5vh;
        --tw: 50vw!important;
        font-size: smaller
    }

    .newsFooter span {
        text-shadow: var(--tw) 0 currentColor,calc(var(--tw) * 2) 0 currentColor,calc(var(--tw) * 3) 0 currentColor
    }
}

@media(max-width: 375px) {
    .newsFooter {
        --tw: 70vw!important;
        font-size: small;
        top: 94.5vh
    }
}

@media(max-width: 320px) {
    .newsFooter {
        --tw: 80vw!important;
        font-size: small
    }
}
