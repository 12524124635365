.accordion-title {
    text-transform: uppercase;
    font-size: 1.5em;
    line-height: 1em;
    font-family: "Helvetica Peel";
    letter-spacing: 2px;
    -webkit-text-stroke-width: medium;
    cursor: pointer;
}

.accordion-content-row {
    color: #fff;
    align-items: flex-end;
    font-family: "Helvetica Peel";
    text-transform: uppercase;
    display: table;
    width: 100%;
    text-align: left;
    letter-spacing: 2px;
    font-weight: bolder;
    -webkit-text-stroke-width: thin;
    font-size: 1.5em;
    margin-top: 10px
}
span.checkoutIcon.icon-peel-arrow-glyph:before {
    padding-right: 11px;
}
.dropdown.accordion {
    width: 100%;
    position: relative;
    display: inline-block;
    margin-top: 20px
}

.dropdown.accordion.open.top {
    margin-top: 90px
}

.dropdown-content.accordion-content {
    width: 61%;
    position: initial
}

a.external.underline {
    border-bottom: 2px solid #fff;
    display: inline-block;
    line-height: initial;
    height: 28px
}

.socialLinks {
    margin-left: 11px
}

.accordion-content-row span.Label {
    text-align: left
}

.accordion-content-row span,.accordion-content-row a {
    display: contents;
    font-weight: bolder;
    font-size: 24px
}

span.checkoutIcon.icon-peel-arrow-glyph {
    padding-right: 20px;
    font-size: .75em
}

.dropdown-content.accordion-content.active {
    display: block
}