.page-nav-container {
    display: flex;
    position: relative;
    left: auto;
    top: auto;
    margin: auto;
    z-index: 99;
    justify-content: space-between;
    font-family: "Helvetica Peel",Arial,Helvetica,sans-serif;
    mix-blend-mode: difference;
}
.left-nav-container {
    text-align: center;
    color: white;
    width: 43%;
    line-height: 11em;
    font-size: 13px;
}
a.left-nav-span-link {
    font-size: 13px;
    transition: none;
    text-transform: initial;
   
}
.left-nav-container.row.mobil-show.mob-nav {
    display: none;
    mix-blend-mode: difference;
}
.left-nav-container, .home-link {
    
    text-transform: initial;
    -webkit-flex-shrink: 0;
    -ms-flex: 0 0 auto;
    flex:0 0 auto;
    flex-shrink: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    
}

a.home-link.FadeOutLogo {
    opacity: 0;
}

.dropdown {
    position: relative;
    font-family: "Helvetica Peel",Arial,Helvetica,sans-serif;
    text-transform: capitalize;
    line-height: 30px;
}
.dropbtn {
    font-family: "Helvetica Peel",Arial,Helvetica,sans-serif;
    text-transform: capitalize;
    color: white;
    border: none;
    background-color: transparent;
    text-align: left;
    line-height: 11em;
    padding: 0;
    font-size: 13px;
}
.dropdown-content {
    display: none;
    position: absolute;
    min-width: 160px;
    z-index: 1;
    top: 85px;
    text-align: left;
  }
  .dropdown-content a {
    color: white;
    text-decoration: none;
    display: block;
    line-height: normal;
  }
  .page-nav-container .dropdown-content a {
	line-height: 2em;
}
.dropdown-content a:hover {color: darken(white, 30%);}

.dropdown:hover .dropdown-content, .dropdown.left-nav-span-link.active .dropdown-content {display: block;}

.page-nav-container .dropdown-content a {
    line-height: 2em;
}
@media (max-width: 840px) {
    .contactsNavContainer .dropdown-content.accordion-content{
        font-size: small;
        }
        .dropbtn {
            color: white;
            border: none;
            background-color: transparent;
            text-align: left;
            padding: 0;
            font-size: 13px;
            line-height: normal;
            margin-left: 0;
            letter-spacing: .15em;
        }
     .dropdown-content {
            display: none;
            min-width: 160px;
            z-index: 1;
            top: 85px;
            text-align: left;
            position: initial;
            padding-bottom: 7px;
     }
}

@media(max-width: 1250px) {
    .max-width {
        width:90%
    }

    a.home-link {
        display: block;
        width: 38%
    }

    .left-nav-container {
        width: 43%
    }
}

@media(max-width: 1024px) {
    .max-width {
        width:90%
    }

    a.home-link {
        display: block;
        width: 50%
    }

    .icon.icon-peel {
        height: 177px
    }
}

@media(max-width: 840px) {
    .contactsNavContainer .dropdown-content.accordion-content {
        font-size:small
    }
    .navi-max-width{
        margin-Bottom: 0;
    }
    .page-wrapper.max-width.News-max-width {
        margin-top: 0;
    }
    a.external.underline {
        border-bottom: 0!important;
        display: inline-block;
        line-height: initial;
        height: 28px;
        text-decoration: underline
    }

    .contactsNavContainer .accordion-content-row {
        margin-top: 20px
    }

    .notReleased {
        font-size: 1.25em;
        margin-top: 20vh
    }

    .max-width.player-max-width {
        width: 90%
    }

    .page-nav-container {
        width: 100%
    }

    a.home-link {
        width: 100%
    }

    .icon-peel:before {
        top: -56px
    }

    .mobile-gone {
        display: none
    }

    .left-nav-container.row.mobil-show.mob-nav {
        width: 100%;
        display: block;
        text-align: left;
        margin-top: 29px;
        position: relative;
        line-height: 4em;
        z-index: 99;
        height: 35px
    }

    .left-nav-container.row.mobil-show.mob-nav.is-active {
        height: auto
    }

    .left-nav-container.row.mobil-show.mob-nav a {
        width: 100%;
        display: block;
        text-align: left;
        line-height: 33px;
        letter-spacing: .15em
    }
    .mobileNavItems {
        height: 0;
    }
    .scrolling-text {
        line-height: 4em;
        font-size: 1.5em
    }

    .dropbtn {
        color: #fff;
        border: none;
        background-color: transparent;
        text-align: left;
        padding: 0;
        font-size: 13px;
        line-height: normal;
        margin-left: 0;
        letter-spacing: .15em
    }

    .dropdown-content {
        display: none;
        min-width: 160px;
        z-index: 1;
        top: 85px;
        text-align: left;
        position: initial;
        padding-bottom: 7px
    }
}


/*!* Hamburgers
* @description Tasty CSS-animated hamburgers
* @author Jonathan Suh @jonsuh
* @site https://jonsuh.com/hamburgers
* @link https://github.com/jonsuh/hamburgers*/
.menu-shield {
    height: 100vh;
    width: 100vw;
    position: fixed;
    display: inline-flex;
    top: 0;
    background-color: transparent;
    overflow: hidden;
    transition: 1s;
    pointer-events: none
}

.left-nav-container.row.mobil-show.mob-nav a,.left-nav-container.row.mobil-show.mob-nav .dropdown.left-nav-span-link {
    opacity: 0;
    pointer-events: none
}

.left-nav-container.row.mobil-show.mob-nav.is-active a,.left-nav-container.row.mobil-show.mob-nav.is-active .dropdown.left-nav-span-link {
    opacity: 1;
    font-family: "Helvetica Peel",Arial,Helvetica,sans-serif;
    pointer-events: auto

}

.is-active .left-nav-container.row.mobil-show.mob-nav a,
.is-active .left-nav-container.row.mobil-show.mob-nav .dropdown.left-nav-span-link  {
    opacity: 1;
    font-family: "Helvetica Peel",Arial,Helvetica,sans-serif;
    pointer-events: auto;
}

.left-nav-container.left-nav-container-five.row.mobil-show.mob-nav {
    transition: 1s;
    height: 40px
}

.left-nav-container.left-nav-container-five.row.mobil-show.mob-nav.is-active {
    height: 100vh;
    transition: 2s;
    opacity: 1;
    width: 100%;
    mix-blend-mode: difference;
}

.navburger {
    font: inherit;
    display: inline-block;
    overflow: visible;
    margin: 0;
    padding: 15px 0 0;
    cursor: pointer;
    transition-timing-function: linear;
    transition-duration: .15s;
    transition-property: opacity,filter;
    text-transform: none;
    color: inherit;
    border: 0;
    background-color: transparent;
    height: 20px
}

.navburger.is-active .navburger-inner,.navburger.is-active .navburger-inner:after,.navburger.is-active .navburger-inner:before {
    background-color: #fff
}

.navburger-box {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 19px
}

.navburger-inner {
    display: block;
    margin-top: -2px;
    background-color: transparent!important
}

.navburger-inner,.navburger-inner:after,.navburger-inner:before {
    position: absolute;
    width: 30px;
    height: 2px;
    transition-timing-function: ease;
    transition-duration: .15s;
    transition-property: transform;
    border-radius: 0;
    background-color: #fff
}

.navburger-inner:after,.navburger-inner:before {
    display: block;
    content: ""
}

.navburger-inner:before {
    top: -8px
}

.navburger-inner:after {
    bottom: -6px;
    width: 2px;
    height: 30px;
    left: 14px
}

.navburger--spin .navburger-inner {
    transition-timing-function: cubic-bezier(.55,.06,.68,.19);
    transition-duration: .22s
}

.navburger--spin .navburger-inner:before {
    transition: top .1s ease-in .25s,opacity .1s ease-in
}

.navburger--spin .navburger-inner:after {
    transition: bottom .1s ease-in .25s,transform .22s cubic-bezier(.55,.055,.675,.19)
}

.navburger--spin.is-active .navburger-inner {
    transition-delay: .12s;
    transition-timing-function: cubic-bezier(.215,.61,.355,1);
    transform: rotate(45deg);
    top: 0
}

.navburger--spin.is-active .navburger-inner:before {
    top: 0;
    transition: top .1s ease-out,opacity .1s ease-out .12s;
    opacity: 1;
    transform: rotate(180deg)
}

.navburger--spin.is-active .navburger-inner:after {
    bottom: 0;
    transition: bottom .1s ease-out,transform .22s cubic-bezier(.215,.61,.355,1) .12s;
    transform: rotate(180deg);
    left: 14px;
    top: -14px
}

.navburger--spin-r .navburger-inner {
    transition-timing-function: cubic-bezier(.55,.055,.675,.19);
    transition-duration: .22s
}

.navburger--spin-r .navburger-inner:before {
    transition: top .1s ease-in .25s,opacity .1s ease-in
}

.navburger--spin-r .navburger-inner:after {
    transition: bottom .1s ease-in .25s,transform .22s cubic-bezier(.55,.055,.675,.19)
}

.navburger--spin-r.is-active .navburger-inner {
    transition-delay: .12s;
    transition-timing-function: cubic-bezier(.215,.61,.355,1);
    transform: rotate(-225deg)
}

.navburger--spin-r.is-active .navburger-inner:before {
    top: 0;
    transition: top .1s ease-out,opacity .1s ease-out .12s;
    opacity: 0
}

.navburger--spin-r.is-active .navburger-inner:after {
    bottom: 0;
    transition: bottom .1s ease-out,transform .22s cubic-bezier(.215,.61,.355,1) .12s;
    transform: rotate(90deg)
}

.navburger-wrap {
    text-align: center
}
