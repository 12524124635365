.fullscreen-bg video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    pointer-events:none;
}
.backgroundVideoPlayer{    
    opacity: 0;

}
.backgroundVideoPlayer.ActiveVideo{   
    opacity:1;
    display:inline;
}
.VideoScrollingText{
    cursor: pointer;
    pointer-events:all;
 
}

.LinkOut{
    display: flex;
    height: 100vh;
    width: 100vw;
    position: absolute;
    left: 0;
    top: 0;
}