.music-box-container {
    border: 2pt solid white;
    font-family: "Helvetica Peel",Arial,Helvetica,sans-serif;
    margin: 7% 0;
    overflow: hidden;
	position: relative;
	border-bottom: 0px;
  mix-blend-mode: exclusion;
}

.music-box-container {
	    border-bottom: 0;
}
.scrolling-text.play-title.wrong-password {
    font-size: 16px;
    line-height: 97px;
}

.hover-text {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 0;
    opacity:0;

}

.scrolling-text.play-title.wrong-password {
    font-size: 16px;
    line-height: 90px;
    letter-spacing: 4px;
}


.js-marquee {
   opacity:1;
   text-shadow: 0;
   line-height: 98px;

}
.play-title{
	opacity: 1;
    width: 100%;
    text-align: center;
    height: 70px;
    
}

.scrolling-text {
    height: 95px;
    border-bottom: 2pt solid white;
    color:white;
    line-height: 3.45em;
    font-size: 1.75em;
    letter-spacing: initial;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    overflow: hidden;


}
.scrolling-text:hover .moving-text {
    opacity:0;

}
.scrolling-text:hover .hover-text{
    opacity:1;

}

.scrolling-text:hover .js-marquee {
    opacity:0;
    text-shadow: 0 0 50px white;

}
.scrolling-text.active-button .hover-text{
     opacity:0;

}
.scrolling-text.active-button .moving-text{
     opacity:0;

}
.scrolling-text.active-button .play-title{
     opacity:1;

}

.icon-play:before, .icon-pause:before{
	    line-height: 106px;
}

.scrolling-text-bottom {
    border-bottom: 0pt solid white;
}

.col-25 {
    width: 25%;
    width: calc(25% - 40px);

	
	
}
.col-25.timebox {
    align-items: right;
    justify-content: right;
}

small.start-time.col-25 {
    text-align: right;
    font-size: 12px;
    letter-spacing: 2px;
    margin-right: 20px;
    opacity: .35;
    line-height: 98px;
}

.col-50 {
    width: 50%;
}

.icon-play, .icon-pause {
    font-size: 1.5em;
    text-align: left;
    opacity: .35;
    margin: 0 0 0 1.5rem;
}


    
   .audio-player .player-controls {
	    align-items: center;
	    justify-content: center;
	    margin-top: 0;
	    height: 10px;
 
	}

    .audio-player .player-controls .progress[value] {
        -webkit-appearance: none;
        appearance: none;
        background-color: white;
        color: #ffffff;
        height: 5px;
    }
	.audio-player .col-100.row {
	    height: 72px;
	    display: flex;
		flex-flow: row;
		width: 100%;
	}	
   

progress:not(value) {
  color:white;
  background-color:white;
}

progress[value] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: white!important;
  width: 100%; 
}
progress[value]::-webkit-progress-bar {
    background-color: #868585;
    opacity: 1;
}

progress[value]::-moz-progress-bar{
   background-image:none;
  background-color: white;
  color:white;
  opacity: 1;
}


progress[value]::-webkit-progress-value{
   background-image:none;
  background-color: white;
  color:white;
  opacity: 1;
}

progress[value]::-webkit-progress-value{
  background-image:none;
  background-color: white;
  color:white;
  opacity: 1;
}


div#seekObjContainer {
    width: 100%;
    height: 9px;
    padding: 10px 0;
    background-color: transparent;
}

progress.seekObj {
    width: 100%;
    height: 5px;
    opacity: .35;
    background-color: white;
    display: block;
}

.video-background {
  display: none;
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.hover-text.active-Hide , .moving-text.active-Hide{
    display: none;
}
span.linebreak.lbtitle {
    margin-left: 11px;
}
div.seekObjContainer {
    margin-top: 8px;
}
.audio-wrapper video {
    display: none;
}
video.broadcast, video.vjs-tech {
    display: block;
    top: unset;
    left: unset;
    position: relative;
    object-fit: unset;
    width: 100%;
    height:auto;
}
.video-box-container {
    max-width: 600px;
    height: auto;
    margin: auto;
    display: block;
}


.video-credit-container {
    color: white;
}

.notplayer { height: 95px; }
.play-title.audio-player.hide-player {display:none;}
.playerontrols.col-100.row.hide-player,.col-100.seekObjContainer.hide-player {
    display: none;
}
.spotifyAd{
    display:none;
}
.spotifyAd.fadeItIn{
    display:block;
}
.fadeItIn {
    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
  }
  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  .fadeItIn, .spotifyAd.fadeItIn {
    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
  }
  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  .fadeItOut {
    animation: fadeOut 5s;
    -webkit-animation: fadeOut 5s;
    -moz-animation: fadeOut 5s;
    -o-animation: fadeOut 5s;
    -ms-animation: fadeOut 5s;
  }
  @keyframes fadeOut {
    0% {opacity:1;}
    100% {opacity:0;}
  }
  
  @-moz-keyframes fadeOut {
    0% {opacity:1;}
    100% {opacity:0;}
  }
  
  @-webkit-keyframes fadeOut {
    0% {opacity:1;}
    100% {opacity:0;}
  }
  
  @-o-keyframes fadeOut {
    0% {opacity:1;}
    100% {opacity:0;}
  }
  
  @-ms-keyframes fadeOut {
    0% {opacity:1;}
    100% {opacity:0;}
  }

@media (max-width: 820px) {
	.music-box-container{
		margin: 30px 0 8% 0;
	}
	
	.moving-text {
    text-align: center;


	}
  .page-wrapper.max-width.Player-max-width {
    margin-top: 30px;
  }

	.hover-text{
		font-size: 1em;
		text-align: center;
	}
	.play-title{
    	font-size: 1em;
	}
	

	
	.js-marquee {
	    padding-top: 0px;
	    font-size: 30px;
	}


}

@media (max-width: 620px) {
	.col-50 {
    	width: 60%;
		}
}


@media (max-width: 530px) {


	 #candrNP .hover-text {
	    line-height: 29px;
	    padding-top: 16px;
	}


	.play-title{
	    font-size: 1em;
		line-height: 98px;
	    margin-bottom: -20px
	}
	.play-title.song-candr {
    	line-height: 30px;
        padding-top: 20px;
	}
	.icon-play, .icon-pause {
	    margin: 0 0 0 24px;
	}
	.icon-pause {
	    font-size: 2em;
	    margin-left: 14px;
	    margin-right: 10px;
	}
	 #candrNP span.linebreak {
	    width: 100%;
	    display: block;
	}

}


@media (max-width: 320px) {
	.play-title.song-candr {
    line-height: 30px;
}
	.col-50 {
   	 width: 60%;
	}
	.col-25 {
    width: 27%;
    width: calc(27% - 40px);
	}
	.icon-pause {
    font-size: 2em;
    margin-left: 0;
    margin-right: 10px;
	}
	small.start-time.col-25{
		    margin-right: 14px;
	}
	
}