.audio-player .col-100.row {
    height: 72px;
    display: flex;
    flex-flow: row;
    width: 100%;
}
.icon-play, .icon-pause {
    font-size: 1.5em;
    text-align: left;
    opacity: .35;
    margin: 0 0 0 1.5rem;
}
.icon-play:before, .icon-pause:before{
    line-height: 106px;
}
.col-25 {
    width: 25%;
    width: calc(25% - 40px);
}
.col-50 {
    width: 50%;
}
.timebox {
    align-items: right;
    justify-content: right;
}
small.start-time.col-25 {
    text-align: right;
    font-size: 12px;
    letter-spacing: 2px;
    margin-right: 20px;
    opacity: .35;
    line-height: 98px;
}
.play-title {
    opacity: 1;
    width: 100%;
    text-align: center;
    height: 70px;
}