.blink {
	-webkit-animation: blink .25s linear infinite;
	-moz-animation: blink .25s linear infinite;
	-ms-animation: blink .25s linear infinite;
	-o-animation: blink .25s linear infinite;
	 animation: blink .25s linear infinite;
}
@-webkit-keyframes blink {
	0% { opacity: 1; }
	50% { opacity: 1; }
	50.01% { opacity: 0; }
	100% { opacity:  0; }
}
@-moz-keyframes blink {
	0% { opacity: 1; }
	50% { opacity: 1; }
	50.01% { opacity:  0; }
	100% { opacity:  0; }
}
@-ms-keyframes blink {
	0% { opacity: 1; }
	50% { opacity: 1; }
	50.01% { opacity: 0; }
	100% { opacity:  0; }
}
@-o-keyframes blink {
	0% { opacity: 1; }
	50% { opacity: 1; }
	50.01% { opacity:  0; }
	100% { opacity:  0; }
}
@keyframes blink {
	0% { opacity: 1; }
	50% { opacity: 1; }
	50.01% { opacity:  0; }
	100% { opacity:  0; }
}
.scrolling-text {
    height: 95px;
    border-bottom: 2pt solid white;
    color: white;
    line-height: 3.45em;
    font-size: 1.75em;
    letter-spacing: initial;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}