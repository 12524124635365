.holder{
    opacity: .3;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
  }
  
  .holder {
      opacity: 0;
      margin-top: -6px;
  }
  .opacityHidden{
     opacity: 0;
  }
  .video-box-container:hover .holder {
      opacity: 1;
  } 
  
  .video-player {
    width: 100%;
    min-width: 300px;
    height: 56px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, .07);
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    -webkit-user-select: none;
    background-color: black;
    
  }
  
  
  .play-pause-btn {
      display: none;
      cursor: pointer;
    }
  
  .icon-fullscreen {
      font-size: 29px;
      color: white;
      box-sizing: border-box;
      text-align: right;
      margin-right: -6px;
      width: 32px;
      /* float: right; */
      display: flex;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-end;
    margin-left: 10px;
  }
  
  .controls {
      color: white;
      width: 97px;
      display: inline-block;
      text-align: center;
      font-size: .75em;
      font-family: "Helvetica Peel";
  }
  
  .middle-controls {
      width: 50%;
  }
  
  .left-controls {
      width: 25%;
      align-items: flex-start;
      display: flex;
      display: flex;
      align-content: center;
      justify-content: space-between;
  }
  
  /* .play-pause-btn svg{
      transform: scale(.8);
      width: 21px;
     
  } */
  /* .volume-btn svg{
    transform: scale(.9);
    width: 21px;
    margin-left: 0px;
  } */

  .play-pause-btn {
    display: flex;
    align-items: flex-start;
  }
  
  .play-pause-btn, .volume-btn, .icon-fullscreen{
    opacity:.5;
  }
  .play-pause-btn:hover, .volume-btn:hover, .icon-fullscreen:hover{
    opacity:1;
  }
  
  .volume .volume-btn.open {
     opacity:1;
  }
  #volume {
      position: relative;
      width: 100%;
      /* height: 5px; */
      /* left: -131px;
      bottom: 9px; */
      /* margin: 0 auto;
      background: #555;
      border-radius: 7px; */
      margin: 0 auto;
      background: #555;
      border-radius: 7px;
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      margin-right: 10px;
  }
  
  #volume .ui-slider-range-min {
        height: 5px;
        width: calc(100% - 24px);
        background: #949393;
        border: none;
        border-radius: 10px;
        outline: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
  }
  
  #volume .ui-slider-handle {
      width: 16px;
      height: 16px;
      border-radius: 8px;
      background: #FFF;
      position: absolute;
      margin-left: -16px;
      left: 100%;
      cursor: pointer;
      outline: none;
  }
  
  .right-controls {
    width: 33%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: nowrap;
    height: inherit;
  }
  .volume {
      width: 25px;
      display: block;
      align-items: flex-start;
      /* float: left; */
      /* position: relative; */
  }
  .controls span {
    margin-top: 0.5px;
    vertical-align: middle;
  }
  .progress-holder {
    width:100%;
    display:block;
    float:left;
  }
  .volume-btn {
    display: flex;
}
  /* .progress-holder .slider {
    flex-grow: 1;
    background-color: #494a4a;
    cursor: pointer;
    position: relative;
    height: 5px;
    opacity: .5
  }
  .progress-holder .slider .progress {
      border-radius: inherit;
      position: absolute;
      pointer-events: none;
      background-color: white;
      height: 100%;
      display: block;
  }
  .progress-holder .slider .progress .pin {
    height: 5px;
    width: 4px;
    background-color: transparent;
    position: absolute;
    pointer-events: all;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.32);
  } */
  div#volume.hidden {
    opacity: 0;
}
  div#seekObjContainer {
    width: 100%;
    height: 9px;
    padding: 10px 0;
    background-color: transparent;
}
progress#seekObj {
    width: 100%;
    height: 5px;
    opacity: .35;
    background-color: white;
    display: block;
}
progress:not(value) {
    color:white;
    background-color:white;
  }
  
  progress[value] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: white!important;
    width: 100%; 
  }
  progress[value]::-webkit-progress-bar {
      background-color: #868585;
      opacity: 1;
  }
  
  progress[value]::-moz-progress-bar{
     background-image:none;
    background-color: white;
    color:white;
    opacity: 1;
  }
  
  
  progress[value]::-webkit-progress-value{
     background-image:none;
    background-color: white;
    color:white;
    opacity: 1;
  }
  
  progress[value]::-webkit-progress-value{
    background-image:none;
    background-color: white;
    color:white;
    opacity: 1;
  }  
  
  @media (max-width: 524px) {
      .left-controls {
          width: 47%;
  
      }
      .right-controls {
        width: 3%;
        }
      .right-controls {
      width: 50%;
      }
  }
  
