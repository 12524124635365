a.home-link {
    display: block;
    width: 57%;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
}
.icon.icon-peel {
    font-size: 25.5em;
    margin-top: 0;
    color: white;
    position: relative;
    overflow: hidden;
    height: 190px;

}
.icon-peel:before {
    content: "\61";
    left: 0;
    position: absolute;
    top: -92px;
    mix-blend-mode: difference;
}

@media (max-width: 1250px) {
    a.home-link {
        display: block;
        width: 38%;
    }
}
@media (max-width: 1024px) {
    a.home-link {
        display: block;
        width: 50%;
    }
    .icon.icon-peel{
        height: 177px;
    }
    
}
@media (max-width: 840px) {
    .icon.icon-peel {
		font-size: 15em;
		height: 110px;
	}
    .icon-peel:before {top: -56px;}
    a.home-link {
		width: 100%;
	}
}
@media (max-width: 560px) {
    .icon.icon-peel {
		height: 106px;
	}
  
}
@media (max-width: 375px) {
    .icon.icon-peel {
		height: 103px;
	}
  
}
